<template>
	<v-layout class="btx-search-criteria">
		<v-flex md10>
			<div class="d-flex">
				<h6>Search Criteria</h6>
				<em class="text-muted ml-3 fw-600">(press to ESC to clear filter)</em>
			</div>
			<ul>
				<template v-if="search">
					<template>
						<li>
							{{ searchString }} have a match for
							<span class="search-text">{{ search }}</span>
						</li>
						<li v-for="(field, index) in sortedFields" :key="index">
							{{ field.label }} {{ field.text }}
							<span class="search-text">{{ query[field.model] }}</span>
							<template v-if="sortedFields[index + 1]"> (and)</template>
						</li>
					</template>
				</template>
				<!-- <template v-else>
					<template v-for="(param, index) in listingSearch">
						<li v-if="param" :key="index" class="font-weight-500 font-size-14">
						<template v-if="typeof param == 'object'"
							>{{ lodash.capitalize(lodash.lowerCase(index)) }} between
						</template>
						<template v-if="typeof param == 'string'"
							>{{
							getLabelNew(index)
							}}
							contains 
						</template>
						<span class="font-weight-700">{{ param }}</span>
						<template v-if="lodash.findLastKey(listingSearch) != index">
							(and)
						</template>
						</li>
					</template>
				</template> -->

				<!-- <li v-if="search">
					{{ searchString }} have a match for
					<span class="search-text">{{ search }}</span>
				</li>
				<li v-for="(field, index) in sortedFields" :key="index">
					{{ field.label }} {{ field.text }}
					<span class="search-text">{{ query[field.model] }}</span>
					<template v-if="sortedFields[index + 1]"> (and)</template>
				</li> -->
			</ul>
		</v-flex>
		<v-flex md2 class="text-right">
			<v-btn
				depressed
				color="red lighten-1"
				class="text-white"
				tile
				text
				small
				@click="$emit('close-search', true)"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>
<script>
import { filter } from "lodash";
export default {
	data() {
		return {};
	},
	props: {
		searchString: {
			type: String,
			default: null,
		},
		searchFields: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	computed: {
		search() {
			const { search } = this.$route.query;
			return search;
		},
		query() {
			return this.$route.query;
		},
		sortedFields() {
			const _this = this;
			return filter(_this.searchFields, function (row) {
				return _this.query[row.model];
			});
		},
	},
};
</script>
